import React, { useContext, useEffect, useState } from 'react';
import { apiFetch, downloadFile } from './API';
import { Link } from 'react-router-dom';
import { ChatPrint, Comment } from './Chat';
import { sio } from './socket';
import { FunnelChart } from './drawGraph';

export const ChatDisplayInPhone = ({messages}) => {

  useEffect(()=>{
    //console.log(messages)
  },[])

  return (
    <>
    {messages.map((chat, index)=>{
        return <div className='chat'>
            <p className='logId'>{chat.nickname}</p>
            <p className='logContent'>{chat.message}</p>
        </div>
    })}
    </>
  );
};

export const ChatDisplayForStreamer = ({messages, popUp}) => {

    const popup = (id) => {
        popUp(id)
        console.log(id)
    }
  
    return (
        <>
        {messages.map((chat, index)=>{
            return <div className={'chat ' + (chat.label == 1? 'needComment':'')}>
                <p className='logId' style={{color: chat.is_manager ? '#86A8ED': 'black'}}>{chat.nickname}</p>
                <p className='logContent' style={{color: chat.type==1 ? '#E57F30' : 'black'}}>{chat.message}{chat.type == 1 ? <span className="material-symbols-outlined" onClick={()=>popup(chat.id)}>prompt_suggestion</span>: <></>}</p>
            </div>
        })}
        </>
    );
  };
  
export const ChatTimeSentiments = ({roomId, messages, isResolve, setResolveCnt}) => {
    return (
        <div className="sub active" id="TimeSentiments">
            <div className="sentimentSub sentimentUl" id="TS-1">
                <div className="textLeft">닉네임</div>
                <div className="textLeft">문의 내용</div>
                <div className="textRight">시간</div>   
                <div className="textRight"><Comment cmt="negative"/>부정도</div>
                <div className="textRight">답변</div>
            </div>
            <ChatPrint roomId={roomId} messages={messages} isSort={1} isResolve={isResolve} setResolveCnt={setResolveCnt}/>
        </div>
    )
}

export const ChatNegativeSentiments = ({roomId, messages, isResolve, setResolveCnt}) => {
    return (
        <div className="sub active" id="NegativeSentiments">
            <div className="sentimentSub sentimentUl" id="NS-1">
                <div className="textLeft">닉네임</div>
                <div className="textLeft">문의 내용</div>
                <div className="textRight">시간</div>
                <div className="textRight"><Comment cmt="negative"/>부정도</div>
                <div className="textRight">답변</div>
            </div>
            <ChatPrint roomId={roomId} messages={messages} isSort={0} isResolve={isResolve} setResolveCnt={setResolveCnt}/>
        </div>
    )
}

export const ChatClusters = ({roomId, setClusterSize}) => {
    const [totalCluster, setTotalCluster] = useState(0)
    const [count, setCount] = useState(0)
    const [clusterList, setClusterList] = useState([])

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        // 1초마다 시간을 업데이트하는 interval 설정
        const intervalId = setInterval(() => {
        setCurrentTime(new Date());
        }, 1000);

        // 컴포넌트가 언마운트될 때 interval을 정리
        return () => clearInterval(intervalId);
    }, []);

    useEffect(()=>{
        async function data() {
            const status = await apiFetch(`/${roomId}/clusters`)
            setTotalCluster(status.length)
            setClusterList(status)
        }
        sio.on('cluster_created', () => {
            data()
        })
        return ()=>{
            sio.off('cluster_created')
        }
    },[])

    const setCountCluster = async (number) => {
        const windowSize = [30,50, -1]
        console.log(number)
        setClusterSize(windowSize[number])
        const status = apiFetch(`/${roomId}/clusters/?window_size=${windowSize[number]}`, [], 'POST')
        setCount(number)
        const newList = await apiFetch(`/${roomId}/clusters`)
        setClusterList(newList)
        console.log(clusterList)
    }

    const setTime = (cur, time) => {
        const sec = ((new Date(cur).getTime() - new Date(time).getTime())/1000).toFixed(0);
        return (
            sec < 60 ? sec + '초전' :
            sec < 3600 ? (sec/60).toFixed(0) + '분전' :
            sec < 3600*24 ? (sec/3600).toFixed(0) + '시간전':
            (sec/3600/24).toFixed(0) + '일전'
        )
    }

    useEffect(()=>{
        async function data() {
            const clusterCalculate = apiFetch(`/${roomId}/clusters/?window_size=30`, [], 'POST')
            const status = await apiFetch(`/${roomId}/clusters`)
            setTotalCluster(status.length)
            setClusterList(status)
        }
        data()
     },[])

     const download = async () => [
        await downloadFile(`/${roomId}/clusters/download`)
     ]

    return (
        <>
        <div className="sub issue active" id="clusters">
            <div className="chatEtcMenu">
                <div className="textLeft">
                    <div className='chatEtcMenuTitle'>전체 클러스터</div>
                    <div className='chatEtcMenuContent chatEtcMenuContentSingle' style={{gridTemplateColumns:'repeat(auto-fill,auto)'}}>{totalCluster}개</div>
                </div>
                <div className="textLeft">
                    <div className='chatEtcMenuTitle'>대상 채팅 수 (최근)</div>
                    <div className='chatEtcMenuContent'>
                        <input className='selected' type="radio" id="count30" name="count30" checked={count==0}/>
                        <label for="count30" className='chatEtcMenuButton' onClick={()=>{setCountCluster(0)}}>30개</label>
                        <input className='selected' type="radio" id="count50" name="count50" checked={count==1}/>
                        <label for="count50" className='chatEtcMenuButton' onClick={()=>{setCountCluster(1)}}>50개</label>
                        <input className='selected' type="radio" id="countAll" name="countAll" checked={count==2}/>
                        <label for="countAll" className='chatEtcMenuButton' onClick={()=>{setCountCluster(2)}}>전체</label>
                    </div>
                </div>
            </div>
            <div id="issueSub">
            {clusterList.map((cluster, index) => {
                return <div className="questionBox" id="QB" style={{maxHeight:'473px'}}>
                            <div className="title">
                                <div className="Q">Q.</div>
                                <div className="text">{cluster.summary}</div>
                                <div className="popup" id="B" style={{transform: 'rotate(0deg)'}}>
                                    <span className="material-symbols-outlined">expand_more</span>
                                </div>
                            </div>
                            <div className ="questionList" id="Q">
                                {cluster.chats != undefined ? cluster.chats.map((chat,index)=>{
                                    return <div class="question">
                                                <div class="id">{chat.nickname}</div>
                                                <div class="Qcontent">{chat.message}</div>
                                                <div class="timeStamp time">{setTime(currentTime, chat.timestamp)}</div>
                                            </div>
                                }): <></>}
                            </div>
                        </div>
            })}
            </div>
        </div>
        <div className='download' onClick={download}>주제 별 분석 엑셀로 내려받기</div>
        </>
    )
}

export const ChatFunnel = ({roomId, messages, isResolve, setResolveCnt}) => {
    const [msg, setMsg] = useState()
    const [graph, setGraph] = useState('freq')
    const [stage, setStage] = useState(0)
    const [currentTime, setCurrentTime] = useState(new Date());

    const [funnel, setFunnel] = useState([0,0,0,0,0,0])

    useEffect(() => {
        async function data() {
            const newList = await apiFetch(`/${roomId}/funnel/?graph=${graph}&stage=${stage}`)
            setFunnel(newList.graph)
            setMsg(newList.chats)
        }

        // 1초마다 시간을 업데이트하는 interval 설정
        const intervalId = setInterval(() => {
        setCurrentTime(new Date());
        }, 1000);
        data()

        // 컴포넌트가 언마운트될 때 interval을 정리
        return () => clearInterval(intervalId);


    }, []);

    useEffect(()=>{
        async function data() {
            const newList = await apiFetch(`/${roomId}/funnel/?graph=${graph}&stage=${stage}`)
            setFunnel(newList.graph)
            setMsg(newList.chats)
        }
        data()
    },[messages])

    const setDrawGraph = async (newStage, newGraph) => {
        const newList = await apiFetch(`/${roomId}/funnel/?graph=${newGraph}&stage=${newStage}`)
        setStage(newStage)
        setGraph(newGraph)
        setFunnel(newList.graph)
        setMsg(newList.chats)
    }

    const setSentimentColor = (sentiment) => {
        if (sentiment > 1) {
            return 'black'
        } else if(sentiment > 0.66) {
            return '#FF0000'
        } else if( sentiment > 0.33) {
            return '#F08A2C'
        } else if(sentiment > 0) {
            return '#008000'
        }
    }

    const setNegativeName = (num) => {
        if(num > 1) {
            return '-'
        } else if(num > 0.66) {
            return '나쁨'
        } else if (num > 0.33) {
            return '중립'
        } else if (num > 0) {
            return '좋음'
        }
    }

    return (
        <>
        <div className="sub active" id="funnel">
            <div className="chatEtcMenu">
                <div className="textLeft">
                    <div className='chatEtcMenuTitle'>그래프</div>
                </div>
                <div className="textLeft">
                    <div className='chatEtcMenuContent'>
                        <input className='selected' type="radio" id="graphFreq" name="graphFreq" checked={graph=='freq'}/>
                        <label for="graphFreq" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(stage, 'freq')}}>채팅 빈도</label>
                        <input className='selected' type="radio" id="graphSentiment" name="graphSentiment" checked={graph=='sentiment'}/>
                        <label for="graphSentiment" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(stage, 'sentiment')}}>센티먼트</label>
                    </div>
                </div>
                <FunnelChart funnel={funnel}/>
                <div className="textLeft">
                    <div className='chatEtcMenuTitle'><Comment cmt="funnel"/>단계</div>
                </div>
                <div className="textLeft">
                    <div className='chatEtcMenuContent chatEtcMenuClick'>
                        <input className='selected' type="radio" id="F0" name="F0" checked={stage == 0}/>
                        <label for="F0" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(0, graph)}}>제품 인식</label>
                        <div className="arrow">→</div>
                        <input className='selected' type="radio" id="F1" name="F1" checked={stage == 1}/>
                        <label for="F1" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(1, graph)}}>제품 이해</label>
                        <div className="arrow">→</div>
                        <input className='selected' type="radio" id="F2" name="F2" checked={stage == 2}/>
                        <label for="F2" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(2, graph)}}>가격·혜택</label>
                        <div className="arrow">→</div>
                        <input className='selected' type="radio" id="F3" name="F3" checked={stage == 3}/>
                        <label for="F3" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(3, graph)}}>배송</label>
                        <div className="arrow">→</div>
                        <input className='selected' type="radio" id="F4" name="F4" checked={stage == 4}/>
                        <label for="F4" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(4, graph)}}>결제·환불</label>
                        <div className="arrow">→</div>
                        <input className='selected' type="radio" id="F5" name="F5" checked={stage == 5}/>
                        <label for="F5" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(5, graph)}}>피드백</label>
                    </div>
                </div>
                <div className="textLeft">
                    <div className='chatEtcMenuTitle'>{graph== 'freq' ? '채팅 빈도' : '센티먼트'}</div>
                </div>
                <div className="textLeft">
                    <div className='chatEtcMenuContent sentimentTitleS'>
                        {funnel != undefined && funnel.map((num)=>{
                            return graph == 'freq' ? <div className="funnelFreq">{num.toFixed(0)}</div> : <div className="funnelFreq" style={{color: setSentimentColor(1-num)}}>{setNegativeName(1-num)}</div>
                        })}
                    </div>
                </div>
                <div className="sentimentSub sentimentUl" id="NS-1" style={{margin: 0}}>
                    <div className="textLeft">닉네임</div>
                    <div className="textLeft">문의 내용</div>
                    <div className="textRight">시간</div>
                    <div className="textRight"><Comment cmt="negative"/>부정도</div>
                    <div className="textRight">답변</div>
                </div>
            </div>
            <ChatPrint roomId={roomId} messages={msg} isSort={2} isResolve={isResolve}/>
        </div>
        </>
    )
}

export const ChatConfirm = ({roomId, messages}) => {
    const [confirmCount, setConfirmCount] = useState(0)
    const [period, setPeriod] = useState(0)
    const [confirmMessages, setConfirmMessages] = useState([])
    const [deduplication, setDeDuplication] = useState(true)
    
    const [regex, setRegex] = useState('\\d{10}')
    const [modify, setModify] = useState(0)
    const [rule, setRule] = useState('')

    const cnt = [-1, 5, 10, 30]

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        // 1초마다 시간을 업데이트하는 interval 설정
        const intervalId = setInterval(() => {
        setCurrentTime(new Date());
        }, 1000);

        // 컴포넌트가 언마운트될 때 interval을 정리
        return () => clearInterval(intervalId);
    }, []);

    useEffect(()=>{
        if(messages != undefined && messages.length > 0){
            if(messages[0].message.match(new RegExp(regex))){
            setConfirmMessages((prev)=>{
                return [messages[0], ...prev]
            })
            setConfirmCount((prev)=>++prev)
            }
        }
    },[messages])

    const setConfirm = async (number, newDeDu) =>{
        const messages = await apiFetch(`/${roomId}/confirms/?minutes_ago=${cnt[number]}&deduplication=${newDeDu}`)
        setConfirmMessages(messages)
        if(newDeDu != deduplication) {
            const tmpMessages = await apiFetch(`/${roomId}/confirms/?minutes_ago=-1&deduplication=${newDeDu}`)
            setConfirmCount(tmpMessages.length)
        }
        setPeriod(number)
        setDeDuplication(newDeDu)
    }

    const setTime = (cur,time) => {
        const sec = ((new Date(cur).getTime() - new Date(time).getTime())/1000).toFixed(0);
        return (
            sec < 60 ? sec + '초전' :
            sec < 3600 ? (sec/60).toFixed(0) + '분전' :
            sec < 3600*24 ? (sec/3600).toFixed(0) + '시간전':
            (sec/3600/24).toFixed(0) + '일전'
        )
    }

    useEffect( ()=>{
        async function data() {
            const status = await apiFetch(`/${roomId}/confirms/?minutes_ago=-1&deduplication=${deduplication}`)
            setConfirmCount(status.length)
            const messages = await apiFetch(`/${roomId}/confirms/?minutes_ago=${cnt[period]}&deduplication=${deduplication}`)
            setConfirmMessages(messages)
            const status2 = await apiFetch(`/${roomId}/status`)
            if(status2.confirm_example != undefined) {
                setRule(status2.confirm_example)
                setModify(1)
            } else {
                setModify(0)
            }
        }
        data()
    },[])

    const download = async () =>{
        await downloadFile(`/${roomId}/confirms/download/?minutes_ago=${cnt[period]}`)
    }
    
    const textModify = async () => {
        if(modify == 1) {
            setModify(0)
        } else if(rule != '') {
            setModify(1-modify)
            if(modify == 0) {
                await apiFetch(`/${roomId}/confirms/rule?example=${rule}`, [], 'PUT')
                const newMsg = await apiFetch(`/${roomId}/confirms/?minutes_ago=${cnt[period]}&deduplication=${deduplication}`)
                setConfirmMessages(newMsg)
                const newMsg2 = await apiFetch(`/${roomId}/confirms/?minutes_ago=-1&deduplication=${deduplication}`)
                setConfirmCount(newMsg2.length)
                const status2 = await apiFetch(`/${roomId}/status`)
                setRule(status2.confirm_example)
            }
        }
    }

    return (
        <>
        <div className="sub active" id="confirm">
            <div className='sentimentUl' style={{height:"162px"}}>
            <div className="chatEtcMenu" style={{height:"130px"}}>
                <div className="textLeft">
                    <div className='chatEtcMenuTitle'>전체 구매인증</div>
                    <div className='chatEtcMenuContent chatEtcMenuContentSingle'>{confirmCount}개</div>
                </div>
                <div className="textLeft">
                    <div className='chatEtcMenuTitle'>선택 기간</div>
                    <div className='chatEtcMenuContent chatEtcMenuContentSingle'>{confirmMessages.length}개</div>
                </div>
                <div className="textLeft">
                    <div className='chatEtcMenuTitle'>조회 기간</div>
                    <div className='chatEtcMenuContent'>
                        <input className='selected' type="radio" id="periodAll" name="periodAll" checked={period==0}/>
                        <label for="periodAll" className='chatEtcMenuButton' onClick={()=>{setConfirm(0,deduplication)}}>전체</label>
                        <input className='selected' type="radio" id="period5" name="period5" checked={period==1}/>
                        <label for="period5" className='chatEtcMenuButton' onClick={()=>{setConfirm(1,deduplication)}}>5분 전</label>
                        <input className='selected' type="radio" id="period10" name="period10" checked={period==2}/>
                        <label for="period10" className='chatEtcMenuButton' onClick={()=>{setConfirm(2,deduplication)}}>10분 전</label>
                        <input className='selected' type="radio" id="period30" name="period30" checked={period==3}/>
                        <label for="period30" className='chatEtcMenuButton' onClick={()=>{setConfirm(3, deduplication)}}>30분 전</label>
                    </div>
                </div>
                <div className="textLeft">
                    <div className='chatEtcMenuTitle'>중복제거</div>
                    <div className='chatEtcMenuContent'>
                        <input className='selected' type="radio" id="dedup" name="dedup" checked={deduplication}/>
                        <label for="dedup" className='chatEtcMenuButton' onClick={()=>{setConfirm(period, true)}}>켜기</label>
                        <input className='selected' type="radio" id="dup" name="dup" checked={!deduplication}/>
                        <label for="dup" className='chatEtcMenuButton' onClick={()=>{setConfirm(period, false)}}>끄기</label>
                    </div>
                </div>
                <div className="textLeft">
                    <div className='chatEtcMenuTitle'>규칙 생성용 채팅<Comment cmt="rule"/></div>
                    <div className='chatEtcMenuContent chatEtcMenuText'>
                        <input type="text" className="textData" id="textInput" placeholder="예시: 1234567890" value={rule} onChange={(e)=>{setRule(e.target.value)}} disabled={modify==0?false:true}/>
                        <div className="textDataButton" style={{color: modify ? 'rgb(134, 168, 237)' : '#818487'}} onClick={textModify}>{modify == 0 ? '확정' : '수정'}</div>
                    </div>
                </div>                
            </div>
            <div className="sentimentSub" id="confirmList">
                <div className="textLeft">닉네임</div>
                <div className="textLeft">인증 내용</div>
                <div className="textLeft">채팅 내용</div>
                <div className="textRight">시간</div>
            </div>
            </div>
            {confirmMessages.map((chat, index) => {
                return <div class="sentimentSub" id={index}>
                            <div class="textLeft">{chat.nickname}</div>
                            <div class="textLeft">{chat.confirm_msg}</div>
                            <div class="textLeft">{chat.message}</div>
                            <div class="timeStamp textRight">{setTime(currentTime, chat.timestamp)}</div>
                        </div>
            })}
        </div>
        <div className='download' onClick={download}>구매인증 리스트 엑셀로 내려받기</div>
        </>
    )
}

export const ChatHarmViewer = ({roomId, messages}) => {
    const [harm, setHarm] = useState(0)
    const [over, setOver] = useState(0)
    const [frequency, setFrequency] = useState(0)
    const [harmList, setHarmList] = useState([])
    const [cnt, setCnt] = useState(0)

    const overList = [0.5, 0.7]
    const freqList = [2,4]

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        // 1초마다 시간을 업데이트하는 interval 설정
        const intervalId = setInterval(() => {
        setCurrentTime(new Date());
        }, 1000);

        // 컴포넌트가 언마운트될 때 interval을 정리
        return () => clearInterval(intervalId);
    }, []);

    const setHarmViewers = async (newOver, newFreq) => {
        setOver(newOver)
        setFrequency(newFreq)
        const newList = await apiFetch(`/${roomId}/harm_viewers/?harm_score_threshold=${overList[newOver]}&harm_freq_threshold=${freqList[newFreq]}`)
        setHarmList(newList)
    }

    useEffect( ()=>{
        async function data() {
            const status = await apiFetch(`/${roomId}/status`)
            setHarm(status.harm_viewer_cnt)
            const newList = await apiFetch(`/${roomId}/harm_viewers/?harm_score_threshold=0.5&?harm_freq_threshold=2`)
            setHarmList(newList)
        }
        data()
    },[])

    useEffect(()=>{
        if(messages != undefined) {
            setCnt((prev)=>++prev)
        }
    },[messages])

    useEffect(()=>{
        async function data() {
            const status = await apiFetch(`/${roomId}/status`)
            setHarm(status.harm_viewer_cnt)
            const newList = await apiFetch(`/${roomId}/harm_viewers/?harm_score_threshold=0.5&?harm_freq_threshold=2`)
            setHarmList(newList)
        }
        if(cnt%10 == 9) {
            setCnt(0)
            data()
        }
    },[cnt])

    const setTime = (cur, time) => {
        const sec = ((new Date(cur).getTime() - new Date(time).getTime())/1000).toFixed(0);
        return (
            sec < 60 ? sec + '초전' :
            sec < 3600 ? (sec/60).toFixed(0) + '분전' :
            sec < 3600*24 ? (sec/3600).toFixed(0) + '시간전':
            (sec/3600/24).toFixed(0) + '일전'
        )
    }

    const setHarmColor = (harm) => {
        if(harm > 0.8) {
            return 'red'
        } else if( harm > 0.6) {
            return 'rgb(235,125,48)'
        } else if (harm > 0.5) {
            return 'rgb(238,190,81)'
        }
    }

    const download = async () => {
        await downloadFile(`/${roomId}/harm_viewers/download/?harm_score_threshold=${overList[over]}&?harm_freq_threshold=${freqList[frequency]}`)
    }

    return (
        <>
        <div className="sub active" id="harm">
            <div className='sentimentUl'>
            <div className="chatEtcMenu">
                <div className="textLeft">
                    <div className='chatEtcMenuTitle'>유해 시청자</div>
                    <div className='chatEtcMenuContent chatEtcMenuContentSingle' style={{gridTemplateColumns:'repeat(auto-fill,auto)'}}>{harm}명</div>
                </div>
                <div className="textLeft">
                    <div className='chatEtcMenuTitle'>역치</div>
                    <div className='chatEtcMenuContent'>
                        <input className='selected' type="radio" id="over5" name="over5" checked={over==0}/>
                        <label for="over5" className='chatEtcMenuButton' onClick={()=>{setHarmViewers(0, frequency)}}>5점</label>
                        <input className='selected' type="radio" id="over7" name="over7" checked={over==1}/>
                        <label for="over7" className='chatEtcMenuButton' onClick={()=>{setHarmViewers(1, frequency)}}>7점</label>
                    </div>
                </div>
                <div className="textLeft">
                    <div className='chatEtcMenuTitle'>빈도</div>
                    <div className='chatEtcMenuContent'>
                        <input className='selected' type="radio" id="freq2" name="freq2" checked={frequency==0}/>
                        <label for="freq2" className='chatEtcMenuButton' onClick={()=>{setHarmViewers(over, 0)}}>2회+</label>
                        <input className='selected' type="radio" id="freq4" name="freq4" checked={frequency==1}/>
                        <label for="freq4" className='chatEtcMenuButton' onClick={()=>{setHarmViewers(over, 1)}}>4회+</label>
                    </div>
                </div>
            </div>
            <div className="sentimentSub hermList">
                <div className="textLeft">닉네임</div>
                <div className="textLeft">유해 점수<Comment cmt='harm'/></div>
                <div className="textLeft">채팅 이력<Comment cmt='chatLog'/></div>
                <div className="textRight">시간</div>
            </div>
            </div>
            {harmList.map((user, index) => {
                return (<>
                    {user != undefined ? user.harm_chats.map((data, index)=>{
                        return (
                            <div className="sentimentSub hermList">
                                <div className="textLeft overflow">{index==0 ? user.nickname : ''}</div>
                                <div className="textLeft" style={{color: setHarmColor(data.harm_score)}}>{(data.harm_score*10).toFixed(2)}</div>
                                <div className="textLeft">{data.message}</div>
                                <div className="textRight">{setTime(currentTime, data.timestamp)}</div>
                            </div>
                        )
                }):<></>}
                </>)
            })}
        </div>
        <div className='download' onClick={download}>유해 시청자 엑셀로 내려받기</div>
        </>
    )
}