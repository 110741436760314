import React, { useEffect, useRef, useState } from "react";
import './css/intro.css'
import './css/iphone.css'
import './css/issue.css'
import { Link } from "react-router-dom";
import { ChatClusters, ChatConfirm, ChatDisplayForStreamer, ChatDisplayInPhone, ChatFunnel, ChatHarmViewer, ChatNegativeSentiments, ChatTimeSentiments } from "./snippet/ChatDisplay";
import { AIChat, ChatAnalysis } from "./snippet/ChatAnalysis";
import { apiFetch } from "./snippet/API";
import { sio } from "./snippet/socket";
import { ChatLog, Comment } from "./snippet/Chat";

export function DemoPage({version}) {
    const [whichAnalysis, setWhichAnalysis] = useState(0)
    const [translate, setTranslate] = useState('translate(0px,28px)')
    const [room, setRoom] = useState(-1)
    
    const [messages, setMessages] = useState([])

    const [viewerMsg, setViewerMsg] = useState('')
    const [managerMsg, setManagerMsg] = useState('')

    const [popUp, setPopUp] = useState(-1)

    const [isResolve, setIsResolve] = useState(false)
    const [resolveCnt, setResolveCnt] = useState(0)

    const [clusterSize, setClusterSize] = useState(30)

    const [os, setOS] = useState('');
    const [iframe, setIframe] = useState(false)

    const setAnalysis = (id) => {
        if(id=='radioTime') {
            setTranslate('translate(0,28px)')
            setWhichAnalysis(0)
        } else if(id == 'radioSentiment') {
            setTranslate('translate(69px,28px) scaleX(1.3)')
            setWhichAnalysis(1)
        } else if(id == 'radioSubject') {
            setTranslate('translate(151px, 28px) scaleX(1)')
            setWhichAnalysis(2)
        } else if(id == 'radioFunnel') {
            setTranslate('translate(144px, 28px) scaleX(1.3)')
            setWhichAnalysis(3)
        } else if(id == 'radioBuy') {
            setTranslate('translate(218px, 28px) scaleX(1.2)')
            setWhichAnalysis(4)
        } else if(id == 'radioHazard') {
            setTranslate('translate(289px, 28px) scaleX(1.2)')
            setWhichAnalysis(5)
        }
    }

    const detectOS = () => {
        const platform = navigator.platform.toLowerCase();
        if (platform.indexOf('mac') !== -1) {
            return 'mac';
        } else if (platform.indexOf('win') !== -1) {
            return 'windows';
        } else if (platform.indexOf('linux') !== -1) {
            return 'linux';
        }
        return 'unknown';
    };

    //startpoint
    useEffect(() => {
        const fetchData = async () => {
          try {
            const result = await apiFetch('/rooms/', null, 'GET');
            result.forEach((room)=>{
                if(room.subject == version) {
                    setRoom(room.id)
                    //console.log(room)
                }
            })
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
        const osType = detectOS();
        setOS(osType)
      }, []);

      const chatEmit = (person, msg) => {
        if(person == 'manager') {
            sio.emit('new_manager_chat', {sauceflex_id: version, content: msg, answer_to: []})
            setManagerMsg('')
        } else if (person == 'viewer') {
            sio.emit('new_viewer_chat', {sauceflex_id: version, nickname: 'viewer', content: msg})
            setViewerMsg('')
        }
      }

    return (
        <>
        <ChatLog roomName={version} messages={messages} setMessages={(e)=>{setMessages(e)}} setResolveCnt={setResolveCnt}/>
        <link rel="stylesheet" href="https://unpkg.com/bs-brain@2.0.3/components/charts/chart-9/assets/css/chart-9.css"></link>
    <div className="grip">
        <div style={{display: 'flex',alignItems: 'center',justifyContent: 'center',flexDirection: 'row'}}>
            <Link to="/" className="logo" style={{cursor: 'pointer'}}>
                <img src="/img/enhance_logo_white.png" width="100"className="live-button"/>
            </Link>
            <div style={{width: '10px',height: '15px',border: '0.5px white solid',borderWidth: '0 0.5px 0 0'}}></div>
            <div style={{color: 'white',fontSize: '12px', paddingLeft: '10px'}} id="roomSubject">{version}</div>
        </div>
    </div>
    <div className="main">
        <div className="chatting">
            <div className={"checkBox "+os}>
                    <input type="checkbox" id="iframe" name="iframe"/>
                    <label for="iframe" onClick={()=>{setIframe((prev)=>!prev)}}></label>
                    <div style={{margin: '1px 0 0 5px', color: "#7A7d7E", fontSize: '14px'}}>방송 화면 켜기</div>
            </div>
            <Comment cmt="log" style={{ left: "140px"}}/>
            {iframe ? <iframe src={'https://player.sauceflex.com/broadcast/' + version} className="iphone-x info"></iframe> : 
            <div className="iphone-x info" id="iphone">
                <div className="header">
                    <div>
                    </div>
                    <div>
                        <span className="material-symbols-outlined">ios_share</span>
                        <span className="material-symbols-outlined">volume_up</span>
                    </div>
                </div>
                <div className="interface">
                    <div className="log" id="log">
                        <ChatDisplayInPhone messages={messages}/>
                        <div className="filter"></div>
                    </div>
                    <div className="input" id="iphoneInput">
                        <input type="text" className="textInput" id="textInput" placeholder="메시지를 입력하세요" value={viewerMsg} onChange={(e)=>{setViewerMsg(e.target.value)}}/>
                        <button className="buttonInput" onClick={()=>chatEmit('viewer', viewerMsg)}>전송</button>
                    </div>
                </div>
            </div>}
            
        </div>
        <div className="info" id="chatting">
            <div className="head">
                <div>채팅<Comment cmt='chatting'/></div>
            </div>
            <div className="log2" id="log2">
                <ChatDisplayForStreamer messages={messages} popUp={(e)=>setPopUp(e)}/>
            </div>
            <div className="interface2 textBox" id="interface2">
                <div className="input">
                    <input type="text" className="textInput" id="textInput2" placeholder="메시지를 입력하세요" value={managerMsg} onChange={(e)=>{setManagerMsg(e.target.value)}}/>
                    <button className="buttonInput" id="managerInput" onClick={()=>chatEmit('manager', managerMsg)}>전송</button>
                </div>
            </div>
            <AIChat setPopUp={setPopUp} id={popUp} roomId={room} setManagerMsg={setManagerMsg} version={version}/>
        </div>
        <div className="info" id="liveComment">
            <ChatAnalysis roomId = {room} messages={messages} resolveCnt={resolveCnt}/>
            <div className="checkbar">
                <div className={"radioBox " + os}>
                    <input className="radio" type="radio" id="radioTime" name="radioTime" checked={whichAnalysis == 0}/>
                    <label for="radioTime" onClick={() => {setAnalysis('radioTime')}}>시간 순</label>

                    <input className="radio" type="radio" id="radioSentiment" name="radioSentiment" checked={whichAnalysis == 1}/>
                    <label for="radioSentiment" onClick={() => {setAnalysis('radioSentiment')}}>부정도 순</label>

                    {/*<input className="radio" type="radio" id="radioSubject" name="radioSubject" checked={whichAnalysis == 2}/>
                    <label for="radioSubject" onClick={() => {setAnalysis('radioSubject')}}>주제 별</label>*/}

                    <input className="radio" type="radio" id="radioFunnel" name="radioFunnel" checked={whichAnalysis == 3}/>
                    <label for="radioFunnel" onClick={() => {setAnalysis('radioFunnel')}}>퍼널 분석</label>

                    <input className="radio" type="radio" id="radioBuy" name="radioBuy" checked={whichAnalysis == 4}/>
                    <label for="radioBuy" onClick={() => {setAnalysis('radioBuy')}}>구매인증</label>

                    <input className="radio" type="radio" id="radioHazard" name="radioHazard" checked={whichAnalysis == 5}/>
                    <label for="radioHazard" onClick={() => {setAnalysis('radioHazard')}}>유해채팅</label>


                    <div id="checkedLine" style={{position: 'absolute', width: '54px',height: '2px',backgroundColor: '#0a093d',transition: '0.5s all ease',transform:translate}}></div>
                </div>
                <div className={"checkBox "+os}>
                    <div style={{marginRight: '5px'}}>답변한 문의 숨김</div>
                    <input type="checkbox" id="isResolve" name="isResolve"/>
                    <label for="isResolve" onClick={()=>{setIsResolve((prev)=>!prev)}}></label>
                </div>
            </div>
            <div className="contentBox">
                { whichAnalysis == 0 ? <ChatTimeSentiments roomId = {room} messages={messages} isResolve={isResolve} setResolveCnt={(e)=>{setResolveCnt(e)}}/> 
                : whichAnalysis == 1 ? <ChatNegativeSentiments roomId = {room} messages={messages} isResolve={isResolve} setResolveCnt={(e)=>{setResolveCnt(e)}}/>
                : whichAnalysis == 2 ? <ChatClusters roomId = {room} setClusterSize={(e)=>setClusterSize(e)}/>
                : whichAnalysis == 3 ? <ChatFunnel roomId = {room} messages={messages} isResolve={isResolve} setResolveCnt={(e)=>{setResolveCnt(e)}}/>
                : whichAnalysis == 4 ? <ChatConfirm roomId = {room} messages={messages}/>
                : <ChatHarmViewer roomId = {room} messages={messages}/>}
            </div>
        </div>
    </div>
    <div className="blur" id="blur" style={{display: 'none'}}>
        <div className="explain-dialog dialog1" id="dialogBox">
            <div className="text">
                <div className = 'dialogTitle' id="dialogTitle">자연어 처리를 통한<br/>빈틈없는 실시간 고객 문의 대응</div>
                <br/>
                <div className = "dialogText" id="dialogText">
                    실시간 고객 응대는 중요하지만,<br/>방대한 휴먼 리소스를 요구합니다.<br/><br/>
                    수많은 시청자의 문의가 동시에 발생하고,<br/>그 내용도 제각각이기 때문입니다.<br/><br/>
                    Enhance를 통해 고객 문의를 놓치지 않고,<br/>분류된 문의 현황을 한 눈에 확인해 보세요.<br/><br/>
                </div>
            </div>
            <div className="nav-bar">
                <div className="button back active" id="back" onClick="transform(0,1)">skip</div>
                <div id="navText">1/5</div>
                <div className="button next active" id="next" onClick="transform(2,1)">next</div>
        </div>
    </div>
    </div>
        </>
    )
}