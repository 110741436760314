import './css/App.css';
import { Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { DemoPage } from './demoPage';
import { FirstPage } from './firstPage';
import { apiFetch } from './snippet/API';

function App() {
  const [demoList, setDemoList] = useState([])

  useEffect(()=>{
    async function newList() {
      const data = await apiFetch('/rooms/')
      let roomList = []
      for (let room of data) {
        roomList.push(room.subject)
      }
      setDemoList(roomList)
      return roomList
    }
    newList()
  },[])


  return (
    <div>
      <Routes>
        <Route path="/" element={<FirstPage demoList={demoList}/>}></Route>
        {demoList.map((demo)=>{
          return <Route path={'/dashboard/' + demo} element={<DemoPage version={demo}/>}></Route>
        })}
      </Routes>
    </div>
  );
}

export default App;
