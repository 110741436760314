import React, {useContext, useEffect, useState} from 'react';
import { SentimentChart } from './drawGraph';
import { apiFetch } from './API';
import { Comment } from './Chat';
import { sio } from './socket';


export function ChatAnalysis({roomId, messages, resolveCnt}) {
    const [totalComment, setTotalComment] = useState(-1)
    const [notAnswer, setNotAnswer] = useState(-1)
    const [positivePercent, setPositivePercent] = useState(0)
    const [lastMessageSentiment, setLastMessageSentiment] = useState(0)
    const [init, setInit] = useState([0,0,0,0,0,0,0,0,0,0])
    
    useEffect(()=>{
        async function data(roomId) {
            const analy = await apiFetch(`/${roomId}/status`)
            setTotalComment(analy.all_inquiries)
            setNotAnswer(analy.all_inquiries - analy.resolved_inquiries)
            setInit(analy.sentiment_avg)
            setPositivePercent(analy.sentiment_avg[9])
        }
        if(roomId != undefined) {
            data(roomId)
        }
    },[roomId])

    useEffect(()=>{
        if(messages != undefined) {
            if(messages.length > 0 && !messages[0].is_manager) {
                setTotalComment((prev)=>prev+messages[0].type);
                setNotAnswer((prev)=>prev+messages[0].type);
                setInit((prev)=>{
                    const update = [...prev]
                    update.shift()
                    update.push(messages[0].sentiment.toFixed(2))
                    return update
                })
                if(totalComment > 0) {
                    setPositivePercent((prev)=>(prev*(totalComment-1)+messages[0].sentiment)/totalComment)
                }
            }
        }
        //console.log(totalComment)
    }, [messages.length])

    useEffect(()=>{
        async function data(roomId) {
            const analy = await apiFetch(`/${roomId}/status`)
            setNotAnswer(analy.all_inquiries - analy.resolved_inquiries)
        }
        if(roomId != undefined) {
            data(roomId)
        }
    }, [resolveCnt])

    return (
        <>
            <div className="head">
                <div>실시간 문의 현황</div>
                <div id="loading" className="disable">정리 중</div>
            </div>
            <div className="summaries">
                <div className="summary">
                    <div className="summaryTitle">전체 문의</div>
                    <div className="summaryContent" id="totalComment">{(totalComment < 0 ? 0 : totalComment) + '개'}</div>
                </div>
                <div className="summary">
                    <div className="summaryTitle">미답변</div>
                    <div className="summaryContent" id="notAnswer">{(notAnswer < 0 ? 0 : notAnswer) + '개'}</div>
                </div>
                <div className="summary">
                    <div className="summaryTitle">답변률</div>
                    <div className="summaryContent" id="answerPercent">{( totalComment ? (100-notAnswer/totalComment*100) : 0 ).toFixed(1)+'%'}</div>
                </div>
                <div className="summary">
                    <div className="summaryTitle">실시간 센티먼트<Comment cmt='sentiment'/></div>
                    <div className="summaryContent" id="liveSentiment" style={{transform: 'translate(-20px, -30px)'}}><SentimentChart sentiment={init}/></div>
                </div>
                <div className="summary">
                    <div className="summaryTitle">긍정도<Comment cmt='positive'/></div>
                    <div className="summaryContent" id="positivePercent">{(positivePercent*100).toFixed(1) + '%'}</div>
                </div>
            </div>
        </>
    )
}

export function AIChat({setPopUp, id, roomId, setManagerMsg, version}) {
    const [message, setMessage] = useState({
        "id": '',
        "nickname": '',
        "type": 1,
        "is_resolved": '',
        "answer_id": '',
        "room_id": 0,
        "harm": 0,
        "message": "",
        "timestamp": new Date().toString(),
        "sentiment": 0,
        "is_manager": false,
        "cluster_num": null,
        "is_confirm": false
      })

    const [AIAns, setAIAns] = useState(['로딩중','로딩중'])

    useEffect(()=>{
        async function data() {
            const tmp = await apiFetch(`/${roomId}/chats/${id}`)
            setMessage(tmp)
            const AIAnswer = await apiFetch(`/${roomId}/inquiries/assistance/?ids=${id}`)
            setAIAns(AIAnswer.ai_replies)
        }
        if(id != undefined && id != -1) {
            data()
            setAIAns(['로딩중','로딩중'])
        }
        //console.log(id)
    },[id])

    const cancel = () => {
        setPopUp(-1)
    }

    const setTime = (time) => {
        const sec = ((new Date().getTime() - new Date(time).getTime())/1000).toFixed(0);
        return (
            sec < 60 ? sec + '초전' :
            sec < 3600 ? (sec/60).toFixed(0) + '분전' :
            sec < 3600*24 ? (sec/3600).toFixed(0) + '시간전':
            (sec/3600/24).toFixed(0) + '일전'
        )
    }

    const AIModify = (index) =>{
        setManagerMsg(`${message.nickname}님, ${AIAns[index]}`)
        setPopUp(-1)
    }

    const AISubmit = (index) =>{
        sio.emit('new_manager_chat',{sauceflex_id:version,content:`${message.nickname}님, ${AIAns[index]}`,answer_to:[id]})
        setPopUp(-1)
    }

    return(
        <div className="answer" id="answer" style={{transform: (id == -1 ? 'translateY(0px)': 'translateY(-320px)')}}>
            <div className="clickedChat answerChats">
                <div className="answerTitle">
                    <div>문의 내용</div>
                    <div className="answerButton" onClick={cancel}>취소</div>
                </div>
                <div className="answerContent" id="realChat">
                    <div>
                        <div class="realAnswerId">
                            {message.nickname}
                            <div class="timeStamp">{setTime(message.timestamp)}</div>
                        </div>
                        <div>{message.message}</div>
                    </div>
                </div>
            </div>
            <div className="AIanswer answerChats">
                <div className="answerTitle">
                    AI 추천 답변
                    <div className="loader disable" id="loader"></div>
                </div>
                <div className="answerContent" id="AIChat">
                    <div className="AISuggest">
                        <div className="AIContent">{AIAns[0]}</div>
                        <div className="AIButtons">
                            <div className="AIButton" onClick={()=>AIModify(0)}>수정</div>
                            <div className="AIButton" onClick={()=>AISubmit(0)}>전송</div>
                        </div>
                    </div>
                    <div className="AISuggest">
                        <div className="AIContent">{AIAns[1]}</div>
                        <div className="AIButtons">
                            <div className="AIButton" onClick={()=>AIModify(1)}>수정</div>
                            <div className="AIButton" onClick={()=>AISubmit(1)}>전송</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="similarAnswer answerChats">
                <div className="answerTitle">유사 답변 사례</div>
                <div className="answerContent">유사 답변 사례가 없습니다.</div>
            </div>
        </div>
    )
}